import { toRelativeUrl } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import { useGetUserRolesQuery } from 'api/role'
import { useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { initializeUser } from 'redux/actions/user.action'
import { useAppDispatch, useAppSelector, type RootState } from 'redux/store'

import { initUser } from './authentication'
import { type AuthRole } from './const'

interface ProtectedRouteProps {
  role: AuthRole
}

const userSelector = (state: RootState) => state.user

const ProtectedRoute = ({ role }: ProtectedRouteProps) => {
  const { oktaAuth, authState } = useOktaAuth()
  const { user } = useAppSelector(userSelector)
  const {
    data: userRoles,
    isError,
    isUninitialized,
    isLoading
  } = useGetUserRolesQuery(undefined, { skip: !authState?.isAuthenticated })
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (authState && !authState.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      )
      oktaAuth.setOriginalUri(originalUri)
      oktaAuth.signInWithRedirect().catch(error => {
        console.log(error)
      })
    }
  }, [authState])

  useEffect(() => {
    if (authState !== null && userRoles) {
      const user = initUser(authState, userRoles)
      if (user) dispatch(initializeUser(user))
    }
  }, [authState, userRoles])

  if (isError || (!isUninitialized && !isLoading && userRoles == null)) {
    return <Navigate to="/ise" />
  }

  if (
    authState == null ||
    authState.isAuthenticated == null ||
    !authState?.isAuthenticated
  ) {
    return <h3>Loading...</h3>
  }

  if (
    user &&
    !user.hasRole(role) &&
    !user.groups.includes('App.Trinity.BMX.Users')
  ) {
    return <Navigate to="/unauthorized" />
  }

  return <Outlet />
}

export default ProtectedRoute
