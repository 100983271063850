import { type Deployment } from 'types'
import { formatUnixTimestamp } from 'utils/DateTools'

interface DeploymentLineProps {
  deployment: Deployment
  instance: string | undefined
}

export const DeploymentLine = ({ deployment, instance }: DeploymentLineProps) => {
   const topContent = deployment.deployedByEpoxy ?
     <div className="font-bold">
        <code>{deployment.extensionPackId}</code>, version {deployment.extensionPackGlobalVersion}
     </div>
     :
     <div className="font-bold">
        <code>{deployment.extensionPackId}</code>
     </div>

  const bottomContent = deployment.deployedByEpoxy ?
    <div className="text-gray-600 mb-2">
      Deployed by { deployment.epoxyUser }<br />
      Local version: { deployment.localVersion }<br />
      Last updated: { formatUnixTimestamp(deployment.creationTimestamp) }<br />
      Deployment profiles: { deployment.profileDeployments.length > 0
        ? deployment.profileDeployments.map(profileDeployment => profileDeployment.profileName).join(', ')
        : "N/A" }<br />
    </div>
    :
    <div className="text-gray-600 mb-2">
      Local-only deployment by { deployment.manhattanUser }<br />
      Local version{ instance ? ' on ' + instance : ''}: { deployment.localVersion }<br />
      Last updated: { formatUnixTimestamp(deployment.creationTimestamp) }<br />
      Deployment profiles: { deployment.profileDeployments.length > 0
        ? deployment.profileDeployments.map(profileDeployment => profileDeployment.profileName).join(', ')
        : "N/A" } <br />
    </div>

  return (
    <div className={'flex items-center p-4 bg-white shadow rounded mb-4 ' + getCardBackgroundColor(deployment)} >
      <div className={'flex-grow'}>
        <div className="flex justify-between mb-2">
          { topContent }
        </div>
        <div className="flex justify-between">
          { bottomContent }
        </div>
      </div>
    </div>
  )
}

function getCardBackgroundColor(deployment: Deployment) {
  return deployment.deployedByEpoxy ? 'eds-background--blue-05' : 'eds-background--indigo-05'
}