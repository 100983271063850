import React from 'react'

import { SideBar } from '../sidebar'

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex eds-flex--direction-column h-screen">
      <SideBar />
      {/* Sidebar is 104 px, ensuring centering */}
      <div className="bg-gray-100 p-4 ml-[104px] h-full">{children}</div>
    </div>
  )
}
