import { Card, Icon, Modal, Select, type SingleValue, TextField } from '@nike/eds'
import { useGetLatestDeploymentsQuery } from 'api/deployment'
import { useGetInstancesQuery } from 'api/instance'
import { useEffect, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize';
import { showSnackbar } from 'redux/slices/snackbar.slices'
import { dispatch } from 'redux/store'
import { type Deployment, type Instance } from 'types'

import { DeploymentLine } from './components'


export const DeploymentState = () => {
  const { data: instances, isLoading: isInstanceDataLoading, isError: isInstanceDataLoadError } = useGetInstancesQuery()
  const emptySelectOption = { label: '', value: '' }
  const [ instanceOptions, setInstanceOptions] = useState([emptySelectOption])
  const [selectedInstance, setSelectedInstance] = useState(emptySelectOption)
  const [searchValue, setSearchValue] = useState<string>('')
  const { data: deployments, isLoading: isDeploymentDataLoading, isError: isDeploymentDataLoadError } = useGetLatestDeploymentsQuery({})
  const [filteredDeployments, setFilteredDeployments] = useState<Deployment[]>([])
  const [selectedDeployment, setSelectedDeployment] = useState<Deployment|undefined>(undefined)

  useEffect(() => {
    if (isDeploymentDataLoading){
      return
    }
    
    let results = deployments
    
    if (selectedInstance?.value && results !== undefined) {
        console.log(selectedInstance.value)
      results = results
        .filter(deployment => {console.log(deployment.instanceId); return deployment.instanceId === selectedInstance.value})
    }

    if (searchValue.length > 0 && results !== undefined) {
      results = results
        .filter(deployment =>JSON.stringify(deployment).toLowerCase().includes(searchValue.toLowerCase()))
    }

    if ( results !== undefined) {
        setFilteredDeployments(results)
    }
  },[selectedInstance, searchValue])

  useEffect(() => {
    if(deployments) {
      setFilteredDeployments(deployments)
    }
  }, [deployments])

  useEffect(() => {
    if(isInstanceDataLoading) {
      return
    }
    if(instances){
      const options : Array<{ label:string, value:string }> = []
      instances.forEach((instance: Instance) => {
        options.push({ label: instance.name, value: instance.id })
      })
      setInstanceOptions(options)
    }
  }, [instances, isInstanceDataLoading])

  useEffect(() => {
    if(isDeploymentDataLoadError){
      dispatch(showSnackbar('Error Loading Deployment data'))
    }
    if(isInstanceDataLoadError){
      dispatch(showSnackbar('Error Loading Instance data'))
    }
  }, [isDeploymentDataLoadError, isInstanceDataLoadError])

  const handleClose = () => {
    setSelectedDeployment(undefined)
  }

  const updateSelectedInstance = (input: SingleValue<{   label: string;   value: string; }>) => {
    setSelectedInstance((input !== null) ? {
      label: input?.label,
      value: input?.value
    } : emptySelectOption)
  }

  return (
    <div className="md:w-4/5 w-full m-auto mt-2">
      <div className="flex items-center place-content-between mb-4">
        <div className="py-5 eds-type--title-1">
          Deployments EP
        </div>
      </div>
      <div className={"flex flex-row"}>
        {instanceOptions.length > 0 &&
          <div className='mb-8 mr-8 w-5/6 pr-1'>
            <Select id={'instanceConfig-selector'}
                    className={''}
                    isClearable={true}
                    label={'Instance:'}
                    value={selectedInstance}
                    onChange={(option) => {
                     updateSelectedInstance(option)
                    }} options={instanceOptions} />
          </div>
        }
        <div className="mb-8 mr-8 w-5/6 pr-1">
          <TextField
            id='extensionPacks'
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value)
            }}
            label='Search extension packs'
            beforeSlot={<Icon name='Search' />}
            afterSlot={<Icon name='Close' onClick={() => {
              setSearchValue('')
            }} />}
          />
        </div>
      </div>
      {deployments && deployments.length > 0
        ? (
          <div className={'flex flex-wrap mb-6'}>
            {filteredDeployments.map((ep: Deployment) => (
              <div key={`div_${ep.id}`}
                   className={"basis-1/2 pr-10"}
                   onClick={() => { setSelectedDeployment(ep) } }>
                {<DeploymentLine key={ep.id} deployment={ep} instance={instances?.find(instance => instance.id === ep.instanceId)?.name } />}
              </div>
            ))}
          </div>
        )
        : (
          <Card className={'w-full border'}>
            <span className={'text-center'}>No extension pack deployments to display</span>
          </Card>
        )
      }
      <Modal headerSlot={<code className="font-bold">{selectedDeployment?.extensionPackId}</code>}
             isOpen={selectedDeployment!==undefined}
             hideFade={true}
             onDismiss={handleClose}>
        <div className="bg-white w-800 rounded">
          <code>
            <TextareaAutosize minRows={3} readOnly className="w-full" value={ JSON.stringify(selectedDeployment, null, 2) } />
          </code>
        </div>
      </Modal>
    </div>
  )
}