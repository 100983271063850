import { Button, Select, TextField } from "@nike/eds";
import { Play } from '@nike/nike-design-system-icons'
import { useSubmitPublicationMutation } from "api/publication";
import { ConfirmationModal } from "components/confirmation";
import { useState, useEffect } from "react";
import { showSnackbar } from "redux/slices/snackbar.slices";
import { dispatch } from "redux/store";
import { type Instance } from "types/Instance";
import { type NewPublication, type PublicationCreationRequest } from "types/Publication";

export interface NewPublicationFormProps {
    instances: Instance[] | undefined
    isLoading: boolean
    isError: boolean,
    submitDefaultPublication: (values: PublicationCreationRequest) => void
}

const initialNewPublication: NewPublication = {
    extensionPackId: '',
    extensionPackLocalVersion: '',
    instance: { 'label': '', 'value': ''}
}

export const NewPublicationForm = ({ instances, isLoading, isError, submitDefaultPublication }: NewPublicationFormProps) => {
    const [newPublication, setNewPublication] = useState<NewPublication>(initialNewPublication)
    const [modalOpen, setModalOpen] = useState(false);
    const [submitPublication, { isLoading: isSubmitting, isError: submitError }] = useSubmitPublicationMutation();
    const [options, setOptions] = useState([{ label: '', value: ''}])
    const [validationErrors, setValidationErrors] = useState({extensionPackId: '', extensionPackLocalVersion: '', instance: ''})
    const confirmationTitle = 'Are you sure you want to publish this Extension Pack?'

    const createPublicationRequest = () => {
        return {
          extensionPackId: newPublication.extensionPackId,
          extensionPackLocalVersion: newPublication.extensionPackLocalVersion,
          sourceInstanceId: newPublication.instance.value,
        };
      };

    const submitPublicationHandler = () => {
        const publicationCreationRequest = createPublicationRequest();
        if(validatePublication(newPublication)) {
            submitPublication(publicationCreationRequest)
        }
    }

    const validatePublication = (newPublication: NewPublication): boolean => {
        const errors = {
            extensionPackId: '',
            extensionPackLocalVersion: '',
            instance: ''
        }
        if (newPublication.extensionPackId.trim() === '') {
            errors.extensionPackId = 'Extension Pack ID is required'
        }

        if (newPublication.extensionPackLocalVersion.trim() === '') {
            errors.extensionPackLocalVersion = 'Version is required'
        }
        if (newPublication.instance.label.trim() === '') {
            errors.instance = 'Instance is required'
        }
        setValidationErrors(errors)
        return Object.values(errors).find(error => error) === undefined
    }

    const savePublication = () => {
        if (validatePublication(newPublication)) {
            setModalOpen(true)
        }
    }

    useEffect(() => {
        if (isLoading) {
            return
        }
        if (instances) {
            const mappedProfiles = instances.map((instance: Instance) => {
                return { label: instance.name, value: instance.id };
            });
            setOptions(mappedProfiles)
        }
    }, [instances, isLoading])

    useEffect(() => {
        if (!isSubmitting && !submitError) {
            setNewPublication(initialNewPublication)
        }
        if (submitError) {
            dispatch(showSnackbar({ message: 'Failed to submit publication', type: 'error' }))
        }
    }, [isSubmitting, submitError])

    const handleSelect = (instance: any) => {
        setValidationErrors({
            ...validationErrors,
            instance: ''
        })
        setNewPublication(prevPublication => ({
            ...prevPublication,
            instance
        }))
    }

    const handleChange = (id: string, value: string) => {
        setValidationErrors({
            ...validationErrors,
            [id]: ''
        })
        setNewPublication({
            ...newPublication,
            [id]: value,
        })
    }

    return (
        <>
            {isLoading || isError ?
                (
                    <span className={'text-center'}>No items to display</span>
                ) :
                (
                    <div className="flex auto h-28">
                        <div className="w-1/5">
                            <TextField
                                id={'extensionPackId'}
                                label={'Extension Pack ID'}
                                placeholder={'Enter Extension Pack ID'}
                                className={'mr-4'}
                                value={newPublication.extensionPackId}
                                hasErrors={validationErrors.extensionPackId !== ''}
                                errorMessage={validationErrors.extensionPackId}
                                onChange={e => { handleChange(e.target.id, e.target.value) }}
                            />
                        </div>
                        <div className="w-1/5">
                            <TextField
                                id={'extensionPackLocalVersion'}
                                label={'Version'}
                                className={'mr-4'}
                                placeholder={'Enter Version'}
                                hasErrors={validationErrors.extensionPackLocalVersion !== ''}
                                errorMessage={validationErrors.extensionPackLocalVersion}
                                value={newPublication.extensionPackLocalVersion}
                                onChange={e => { handleChange(e.target.id, e.target.value) }}
                            />
                        </div>
                        <div className="flex-grow">
                            <Select
                                id={'instance'}
                                label={'Instance'}
                                placeholder={'Select'}
                                className={'mr-8'}
                                options={options}
                                hasErrors={validationErrors.instance !== ''}
                                errorMessage={validationErrors.instance}
                                value={newPublication.instance}
                                onChange={handleSelect}
                            />
                        </div>
                        <div className="place-self-end mb-4">
                            <Button
                                beforeSlot={<Play />}
                                style={{ height: '56px' }}
                                size="medium"
                                variant="primary"
                                onClick={() => { savePublication() }}>
                                Publish
                            </Button>
                        </div>
                        {modalOpen && (
                            <ConfirmationModal handleCloseModal={() => { setModalOpen(false) } } submit={submitPublicationHandler} rollout={newPublication} title={confirmationTitle} />
                        )}
                    </div>
                )}
        </>
    )
}