import { Icon, Spinner } from '@nike/eds'
import { useGetDeploymentQuery } from 'api/deployment'
import { useEffect, useState } from 'react'
import { Step, Stepper } from 'react-form-stepper'
import { useParams } from 'react-router-dom'
import { CompletionStatus } from 'types'

import { DeploymentVisualisationHeader } from './components'

export const DeploymentVisualisation = () => {
    const { deploymentId } = useParams()
    const { data: deployment } = deploymentId ? useGetDeploymentQuery(deploymentId) : { data: undefined }
    
    const [errorMessage, setErrorMessage] = useState<string>('')

    useEffect(() => {
        if (deployment !== undefined && deploymentId !== undefined && deployment.stages !== undefined) {
            setErrorMessage(deployment.stages[deployment.stages.length - 1].message)
        }
    }, [deployment, deploymentId])

    const getStatusIcon = (status: CompletionStatus = CompletionStatus.NOT_STARTED) => {
        if (status === undefined) return (<div className="w-9 h-9 flex bg-gray-300 items-center justify-center rounded-full"><Icon name="Close" size='m' /></div>)
        switch (status) {
            case CompletionStatus.COMPLETED:
                return <div className="w-9 h-9 bg-green-500 flex items-center justify-center rounded-full"><Icon name="Check" size='l' /></div>
            case CompletionStatus.IN_PROGRESS:
                return <div className="w-9 h-9 bg-blue-500 flex items-center justify-center rounded-full"><Icon name="Ellipsis" size='m' /></div>
            case CompletionStatus.FAILED:
                return <div className="w-9 h-9 bg-red-500 flex items-center justify-center rounded-full"><Icon name="Alert" size='l' /></div>
            case CompletionStatus.INTERNAL_ERROR:
                return <div className="w-9 h-9 bg-orange-300 flex items-center justify-center rounded-full"><Icon name="Alert" size='l' /></div>
            case CompletionStatus.NOT_STARTED:
                return <div className="w-9 h-9 flex bg-gray-300 items-center justify-center rounded-full"><Icon name="Close" size='m' /></div>
        }
    }

    const getFormattedStageName = (stage: string) => {
        const stageArr = stage.split('.')
        return stageArr[stageArr.length - 1].toUpperCase()
    }

    return (
        <>
            <div className="md:w-1/2 w-full m-auto mt-2">
                <h1 className="pt-5 eds-type--title-1">
                    Deployment
                </h1>
            </div>
            <div className="flex justify-center items-center">
                <div style={{ marginTop: '6rem' }} className="w-4/5 bg-white eds-elevation--2">
                    {deployment === undefined
                        ? (
                            <div className={'flex justify-center m-20'}>
                                <Spinner size="large" />
                            </div>
                        )
                        : (<>
                            <DeploymentVisualisationHeader deployment={deployment} />
                            <br />
                            <Stepper activeStep={1}>
                            {deployment.stages.map((stage, index) => (
                                <Step key={index} label={getFormattedStageName(stage.name)} onClick={() => {}}>
                                    {getStatusIcon(stage.completionStatus)}
                                </Step>
                            ))}
                            </Stepper>
                            <br />
                            {errorMessage && (
                                <div className="ml-10">
                                    <div className="text-red-600 mb-2">{errorMessage}</div>
                                </div>
                            )}
                        </>)
                    }
                </div>
            </div>
            <div className="mt-6 text-gray-400">
                deployment ID: {deploymentId}
            </div>
        </>
    )
}
