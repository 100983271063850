// React inline styling doesn't support keyframes, these are currently found in the index.css
// Shamelessly stolen from https://loading.io/css/

export enum SpinnerTypes {
  NOT_STARTED = 'ellipsis',
  IN_PROGRESS = 'ring'
}

export interface SpinnerProps {
  type: SpinnerTypes
}
export const StatusSpinner = ({ type }: SpinnerProps) => (
  <div className={`lds-${type} mr-4`}><div></div><div></div><div></div><div></div></div>
)
