import { Snack, Snackbar } from '@nike/eds'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { type SnackbarState, type SnackbarStatus } from 'redux/slices/snackbar.slices'
import './snackbar.css'

export const SnackBar = () => {
  const snackbarState = useSelector((state: { snackbar: SnackbarState }) => state.snackbar)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState<SnackbarStatus>('neutral')

  useEffect(() => {
    if (snackbarState.message !== '') {
      setMessage(snackbarState.message)
      setStatus(snackbarState.status)
      setTimeout(() => {
        setMessage('')
      }, 5000)
    }
  }, [snackbarState])

  const reset = () => {
    setMessage('')
    setStatus('neutral')
  }

  return (
    <>
      {message !== '' &&
        <Snackbar onClick={reset}>
          <Snack id={'snackbar-snack'} status={status}>
            {message}
          </Snack>
        </Snackbar>
      }
    </>
  )
}
