import { type Publication, type PublicationDTO } from "types/Publication";

export const fromPublicationDTO = (publication: PublicationDTO): Publication => ({
    id: publication.id,
    extensionPack: {
        ...publication.extensionPack,
        version: publication.extensionPackLocalVersion
    },
    sourceInstanceName: publication.sourceInstanceName,
    sourceInstanceId: publication.sourceInstanceId,
    timestamp: publication.timestamp,
    user: publication.user.name,
    currentEvent: publication.currentEvent,
    error: publication.error
})