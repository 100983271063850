import {
  type FetchArgs,
  type FetchBaseQueryArgs
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { type BaseQueryApi, fetchBaseQuery } from '@reduxjs/toolkit/query'
import { showSnackbar } from 'redux/slices/snackbar.slices'
import { loadToken } from 'utils/OktaToken'

export const customBaseQuery =
  (baseQueryOptions: FetchBaseQueryArgs) =>
  async (
    args: FetchArgs | string,
    api: BaseQueryApi,
    extraOptions: Record<string, unknown>
  ) => {
    const token = loadToken()
    if (typeof args === 'string') {
      args = { url: args }
    }
    if (token != null) {
      args.headers = { Authorization: `Bearer ${token}` }
    }
    const result = await fetchBaseQuery(baseQueryOptions)(
      args,
      api,
      extraOptions
    )

    if (result.error != null) {
      const error = result.error
      switch (error.status) {
        case 401:
          window.location.href = '/login'
          break
        case 403:
          api.dispatch(
            showSnackbar({ message: 'Forbidden action!', status: 'error' })
          )
          break
        case 404:
          api.dispatch(
            showSnackbar({
              message: `${result.meta!.request.method} ${args.url} not found!`,
              status: 'error'
            })
          )
          break
        default:
          api.dispatch(showSnackbar({ message: error.data, status: 'error' }))
          break
      }
      return { error: { status: error.status, data: error.data } }
    }

    return result
  }
