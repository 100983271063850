import { configureStore } from '@reduxjs/toolkit'
import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { deploymentSlice } from 'redux/slices/deployment.slices'
import { publicationSlice } from 'redux/slices/publication.slices'
import { snackbarSlice } from 'redux/slices/snackbar.slices'
import { reducer as user } from 'redux/slices/user.slices'

import { mainSplitApi } from './mainSplitApi'

export const store = configureStore({
  reducer: {
    user,
    snackbar: snackbarSlice.reducer, 
    deployments: deploymentSlice.reducer,
    publications: publicationSlice.reducer,
    [mainSplitApi.reducerPath]: mainSplitApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      // Required for user
      serializableCheck: false
    }).concat(mainSplitApi.middleware)
  }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const dispatch = store.dispatch
