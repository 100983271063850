import { type Deployment, type DeploymentProgressDTO, type DeploymentStage, type DeploymentProgress, type DeploymentStageDTO, CompletionStatus } from 'types'
import { formatUnixTimestamp } from 'utils/DateTools'

export const fromDeploymentDTO = (deployment: Deployment): DeploymentProgress => ({
    id: deployment.id,
    extensionPackId: deployment.extensionPackId,
    description: '',
    globalVersion: deployment.extensionPackGlobalVersion,
    targetInstanceId: deployment.instanceId,
    targetProfile: '',
    user: "dummy",
    stages: []
})

export const fromDeploymentProgressDTO = (deployment: DeploymentProgressDTO): DeploymentProgress => ({
    id: deployment.id,
    extensionPackId: deployment.extensionPackId,
    description: '',
    globalVersion: deployment.globalVersion,
    targetInstanceId: deployment.targetInstanceId,
    targetProfile: deployment.targetProfile,
    user: deployment.user,
    stages: deployment.stages.map(fromDeploymentStageDTO)
})

const fromDeploymentStageDTO = (stage: DeploymentStageDTO): DeploymentStage => ({
    timestamp: stage.timestamp,
    timestampFormatted: formatUnixTimestamp(stage.timestamp),
    name: stage.name,
    completionStatus: CompletionStatus[stage.completionStatus as keyof typeof CompletionStatus],
    message: stage.message
})
