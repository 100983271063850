import { Icon, Tooltip } from "@nike/eds";
import { SpinnerTypes, StatusSpinner } from "components/status-spinner";
import { useEffect, useState } from "react";
import { CompletionStatus, getPublicationStatus } from "types";
import { type Publication } from "types/Publication";
import { formatUnixTimestamp } from "utils/DateTools";

interface PublicationLineProps {
    publication: Publication;
    onClick: (publication: Publication) => void;
};

export const PublicationLine = ({ publication, onClick }: PublicationLineProps) => {
    const [publicationInfo, setPublicationInfo] = useState<Publication>({
        id: '',
        extensionPack: {
            id: '',
            name: '',
            globalVersion: '',
            version: '',
            description: ''
        },
        sourceInstanceId: '',
        sourceInstanceName: '',
        timestamp: -1,
        user: '',
        currentEvent: '',
        error: ''
});
    useEffect(() => {
        setPublicationInfo(publication)
    }, [publication])

    const renderStatusIcon = (status: string) => {
        switch(getPublicationStatus(status)) {
            case CompletionStatus.COMPLETED:
                return (
                    <Tooltip bodySlot={'Completed'} placement={'left'}>
                        <div className="flex-none w-12 h-12 bg-green-300 flex items-center justify-center rounded-full mr-4">
                            <Icon name="Check" size="m" />
                        </div>
                    </Tooltip>
                )
            case CompletionStatus.NOT_STARTED:
                return (
                    <Tooltip bodySlot={'Not started'} placement={'left'}>
                        <StatusSpinner type={SpinnerTypes.NOT_STARTED} />
                    </Tooltip>
                )
            case CompletionStatus.FAILED:
                return (
                    <Tooltip bodySlot={'Failed'} placement={'left'}>
                        <div className="flex-none w-12 h-12 bg-red-300 flex items-center justify-center rounded-full mr-4">
                            <Icon name="Close" size="m" />
                        </div>
                    </Tooltip>
                )
        }
    }

    const checkValueIsPresent = (value: string) => {
        return value === undefined ? '❌' : value
    }
    
    return (
        <div className={`flex items-center p-4 bg-white shadow rounded mb-4 cursor-pointer`} onClick={() => { onClick(publicationInfo) }}>
            {renderStatusIcon(publicationInfo.currentEvent)}
            <div className="flex-grow">
                <div className="flex justify-between mb-2">
                    <div className="font-bold">
                        {publicationInfo.extensionPack.id} - {publicationInfo.user}
                    </div>   
                </div>
                <div className="eds-grid eds-grid--s-cols-4">
                        <div className="text-gray-600">{`Extension Pack Version: ${publicationInfo.extensionPack.version}`}</div>
                        <div className="text-gray-600">{`Global Version: ${checkValueIsPresent(publicationInfo.extensionPack.globalVersion)}`}</div>
                        <div className="text-gray-600">{`Timestamp: ${formatUnixTimestamp(publicationInfo.timestamp)}`}</div>
                        <div className="eds-grid--item-justify-end">{`Instance: ${publicationInfo.sourceInstanceName}`}</div>
                </div>
            </div>
        </div>
)
}