import { type MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks'
import { fromDeploymentProgressDTO } from 'mapper'
import { mainSplitApi } from 'redux/store/mainSplitApi'
import {
  type DeploymentProgress,
  type DeploymentCreationRequest,
  type DeploymentCreationResponse,
  type DeploymentProgressDTO,
  type Deployment
} from 'types'
import { InitiateSSE } from 'utils/InitiateSSE'

const handleCache = (cache: MaybeDrafted<DeploymentProgress[]>, data: DeploymentProgressDTO) => {
  const deployment: DeploymentProgress = fromDeploymentProgressDTO(data)
  const index = cache.findIndex(d => d.id === deployment.id)
  if (index === -1) {
    cache.unshift(deployment)
  } else {
    cache[index] = deployment
  }
  return cache
}

export const deploymentApi = mainSplitApi.injectEndpoints({
  endpoints: builder => ({
    getDeployments: builder.query<DeploymentProgress[], void>({
      queryFn () {
        return { data: [] }
      },
      async onCacheEntryAdded (
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const abortCtrl = new AbortController() // Create an abort controller for the SSE connection
        try {
          const handleEvent = (data: DeploymentProgressDTO) => {
            updateCachedData(draft => {
              return handleCache(draft, data)
            })
          }
          InitiateSSE(handleEvent, abortCtrl, 'deployments/stream')
          await cacheDataLoaded
        } catch {
          console.log('Unexpected failure')
        }
        await cacheEntryRemoved
        abortCtrl.abort()
      }
    }),
    getLatestDeployments: builder.query({
        query: () => 'deployments',
        transformResponse: (response: Deployment[]) => {
            return response
        }
    }),
    getDeployment: builder.query<DeploymentProgress, string>({
      query: (id) => `deployments/${id}`,
      transformResponse: (response: DeploymentProgressDTO) => {
        const deployment = fromDeploymentProgressDTO(response);
        return deployment;
      }
    }),
    submitDeploy: builder.mutation<DeploymentCreationResponse, DeploymentCreationRequest>({
      query: body => ({
        url: '/deployments',
        method: 'POST',
        body
      })
    })
  }),
  overrideExisting: false
})

export const { useGetDeploymentsQuery, useGetLatestDeploymentsQuery, useGetDeploymentQuery, useSubmitDeployMutation } =
  deploymentApi
