import { type NavSubItem } from '@nike/eds'

export interface NavItem {
  id: string
  paths: string[]
  icon: string | React.ReactElement
  label: string
  active: boolean
  subItems?: NavSubItem[]
  meta?: Record<string, never>
}

export const deployItem = {
  id: 'deploy',
  paths: ['deploy'],
  icon: 'Share',
  label: 'Deploy',
  active: false
}

export const publishItem = {
  id: 'publish',
  paths: ['publish'],
  icon: 'Journal',
  label: 'Publish',
  active: false
}

export const extensionPackViewItem = {
  id: 'deployments',
  paths: ['deployments'],
  icon: 'Data',
  label: 'Deployments',
  active: false
}
