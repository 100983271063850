import { createSlice } from '@reduxjs/toolkit'

export type SnackbarStatus = 'info' | 'success' | 'warning' | 'error' | 'neutral'
export interface SnackbarState {
  message: string
  status: SnackbarStatus
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    message: '',
    status: 'neutral'
  },
  reducers: {
    showSnackbar: (state, action) => {
      state.status = action.payload.status
      state.message = action.payload.message
    }
  }
})

export const { showSnackbar } = snackbarSlice.actions
