import { AuthRole } from 'auth/const'

class User {
  groups: string[]
  roles: AuthRole[]

  constructor (groups: string[], roles: AuthRole[]) {
    this.groups = groups
    this.roles = roles
  }

  hasRole (role: string): boolean {
    return this.roles.filter(ur => ur === role).length > 0
  }

  hasAnyRole (): boolean {
    return Object.values(AuthRole).filter(role => this.hasRole(role)).length > 0
  }
}

export default User
