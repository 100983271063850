import { LoginCallback } from '@okta/okta-react'
import { AuthRole } from 'auth/const'
import ProtectedRoute from 'auth/ProtectedRoute'
import { Layout } from 'components/layout'
import { Deploy } from 'pages/deploy'
import { DeploymentState } from 'pages/deployment-state'
import { DeploymentVisualisation } from 'pages/deployment-visualisation'
import { InternalServerError, Unauthorized } from 'pages/error-pages'
import { Publish } from 'pages/publish'
import { Route, Routes as ReactRoutes, Navigate } from 'react-router-dom'

export const Routes = () => (
  <>
    <ReactRoutes>
      <Route path="/login/callback" element={<LoginCallback />} />

      <Route
        path="/unauthorized"
        element={
          <Layout>
            <Unauthorized />
          </Layout>
        }
      />

      <Route
        path="/ise"
        element={
          <Layout>
            <InternalServerError />
          </Layout>
        }
      />

      <Route element={<ProtectedRoute role={AuthRole.ALL} />}>
        <Route path="/" element={<Navigate to="/deploy" />} />
        <Route path="/deploy" element={
            <Layout>
              <Deploy />
            </Layout>
        } />
        <Route path="/deploy/:deploymentId" element={
          <Layout>
            <DeploymentVisualisation />
          </Layout>
        } />
        <Route path="/deployments" element={
          <Layout>
            <DeploymentState />
          </Layout>
        } />

        <Route path="/publish" element={
            <Layout>
              <Publish />
            </Layout>
        } />
      </Route>

      <Route element={<ProtectedRoute role={AuthRole.ADMIN} />}>
        {/*    */}
      </Route>
    </ReactRoutes>
  </>
)
