import { Card, Icon, TextField } from '@nike/eds';
import { useGetInstancesQuery } from 'api/instance';
import { useGetPublicationsQuery } from 'api/publication';
import { Paging } from 'components/paging'
import { PublicationLine } from 'pages/publish/components';
import { PublicationModal } from 'pages/publish/components/PublicationModal';
import { useEffect, useState } from 'react';
import { CompletionStatus } from 'types';
import { type PublicationCreationRequest, type Publication } from 'types/Publication';

import { NewPublicationForm } from './components/NewPublicationForm';

export const Publish = () => {
  const { data: instances, isLoading, isError } = useGetInstancesQuery()
  const { data: publications } = useGetPublicationsQuery()

  const [searchValue, setSearchValue] = useState<string>('')
  const [showPublications, setShowPublications] = useState<Publication[]>([])
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [selectedPublication, setSelectedPublication] = useState<Publication| undefined>(undefined)
  const [pageCount, setPageCount] = useState<number>(0)
  const [itemOffset, setItemOffset] = useState<number>(0)
  const itemsPerPage = 10

  useEffect(() => {
    const toShowPublications = publications?.map(publication => { 
      const matchingInstance = instances?.find(instance => instance.id === publication.sourceInstanceId);
      return {
        ...publication,
        sourceInstanceName: matchingInstance?.name ?? 'Unknown Instance'
      }
      }).filter(publication => filterBy(publication, searchValue));
    if (toShowPublications !== undefined) {
      setShowPublications(toShowPublications.slice(itemOffset, itemOffset + itemsPerPage))
      setPageCount(Math.ceil(toShowPublications.length / itemsPerPage))
    }
  }, [publications, instances, searchValue, itemOffset])

  const handleClick = (publication: Publication) => {
    setSelectedPublication(publication)
    setModalOpen(true)
  }

  const submitDefaultPublication = (values: PublicationCreationRequest) => {
    const newPublication: Publication = {
      id: values.extensionPackId,
      extensionPack: {
        id: values.extensionPackId,
        version: values.extensionPackLocalVersion,
        globalVersion: '',
        name: '',
        description: ''
      },
      sourceInstanceId: values.sourceInstanceId,
      sourceInstanceName: '',
      user: '',
      timestamp: -1,
      currentEvent: CompletionStatus.NOT_STARTED,
      error: ''
    };
    setShowPublications(prev => [...prev, newPublication])
  }

  return (
    <div className="md:w-4/5 w-full m-auto mt-2">
      <div className="flex items-center place-content-between mb-4">
        <h1 className="py-5 eds-type--title-1">Publication</h1>
      </div>
      <NewPublicationForm instances={instances} isLoading={isLoading} isError={isError} submitDefaultPublication={submitDefaultPublication}/>
      <br />
      <hr />
      <div className="flex flex-col pt-6">
        <div className="mb-8">
          <TextField
            id='publications'
            label=''
            onChange={(e) => { setSearchValue(e.target.value) }}
            beforeSlot={<Icon name='Search' />}
            afterSlot={<Icon name='Close' onClick={() => { }} />}
          />
        </div>
        {showPublications !== null && showPublications.length > 0
          ? (<>
            {showPublications.sort((a,b) => { return b.timestamp - a.timestamp  }).map((publication: Publication) => (
              <div key={`div_${publication.id}`}>
                {<PublicationLine key={publication.id} publication={publication} onClick={handleClick}/>}
              </div>
            ))}
            {modalOpen && selectedPublication ? <PublicationModal publication={selectedPublication} onClose={() => { setModalOpen(false) }} /> : null}
            <div>
              {publications != null && (<Paging items={publications} itemsPerPage={itemsPerPage} setItemOffset={setItemOffset} pageCount={pageCount} />)}
            </div>
          </>) : (
            <Card className={'w-full border'}>
              <span className={'text-center'}>No publications found</span>
            </Card>
          )}
      </div>
    </div>
  )
}

const createFilters = (
  searchValue: string
): Array<(publication: Publication) => boolean> => [
    publication => publication.extensionPack.id.toLowerCase().includes(searchValue.toLowerCase()),
    publication => publication.extensionPack.globalVersion?.toLowerCase().includes(searchValue.toLowerCase()),
    publication => publication.sourceInstanceName.toLowerCase().includes(searchValue.toLowerCase()),
    publication => publication.user.toLowerCase().includes(searchValue.toLowerCase()),
  ]

export const filterBy = (toFilter: Publication | undefined, searchValue: string): boolean => {
  if (toFilter !== undefined && searchValue !== '') {
    const filters = createFilters(searchValue);
    return filters.some(filter => filter(toFilter));
  }
  return true;
}