import { Label, Modal, Tab, TabGroup, TextArea } from "@nike/eds";
import { useEffect, useState } from "react";
import { type Publication } from "types/Publication";

interface PublicationModalProps {
    publication: Publication;
    onClose: () => void;
}

export const PublicationModal = ({ publication, onClose }: PublicationModalProps) => {
    const [activeTab, setActiveTab] = useState('error-message')
    const showActiveTab = () => {
        
        switch (activeTab) {
            case 'error-message': {
                return <TextArea id={"error-message"}
                        label={"error-message"}
                        hideLabel={true}
                        disabled={true}
                        minRows={10}
                        value={publication.error}/>
            }
            case 'description': {
                return <TextArea id={"description"}
                        label={"description"}
                        hideLabel={true}
                        disabled={true}
                        minRows={10}
                        value={publication.extensionPack.description}/>
            }
        }
    }
    useEffect(() => {
        setActiveTab(publication.error !== '' ? 'error-message' : 'description')
    }, [publication])

    const checkValueIsPresent = (value: string) => {
        return value === undefined ? '❌' : value
    }

    return <Modal isOpen={true} headerSlot={<div content="Header placeholder" />} onDismiss={onClose}>
        <div className="flex-col">
            <div className="whitespace-nowrap">
                <div className="mb-4 flex items-baseline">
                    <Label font="title-6">Extension Pack ID:</Label>
                    <div className="ml-2">{publication.extensionPack.id}</div>
                </div>
                <div className="mb-4 flex items-baseline">
                    <Label font="title-6">Version:</Label>
                    <div className="ml-2">{publication.extensionPack.version}</div>
                </div>
                <div className="mb-4 flex items-baseline">
                    <Label font="title-6">Global Version:</Label>
                    <div className="ml-2">{checkValueIsPresent(publication.extensionPack.globalVersion)}</div>
                </div>
            </div> 
            <hr className={'my-4'}/>
            <TabGroup activeId={activeTab} name={"publication-detail-view"} onChange={e => { setActiveTab(e.target.id) }}>
                {publication.error !== '' ? <Tab id='error-message'>Error message</Tab> : <Tab id='description'>Description</Tab>}
            </TabGroup>
            <div className={"mt-4 min-w-[420px]"}>
                {showActiveTab()}
            </div>
        </div>
    </Modal>
}