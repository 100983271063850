import { fetchEventSource } from '@microsoft/fetch-event-source'
// import { type SnackbarContextType } from 'components/snackbar'

import { loadToken } from './OktaToken'

type MessageHandler = (data: any) => void

export const InitiateSSE = (onEvent: MessageHandler, ctrl: AbortController, endpoint: string): void => {
  let errorCounter: number = 0
  const token = loadToken()

  if (token === null) {
    // snackbar.showSnackbar('Error fetching token', 'error')
    return
  }
  process.env.REACT_APP_API_URL !== undefined
    ? fetchEventSource(ensureTrailingSlash(process.env.REACT_APP_API_URL) + endpoint, {
      method: 'GET',
      signal: ctrl.signal,
      headers: {
        Authorization: `Bearer ${token}`
      },
      onmessage (msg) {
        if (msg.data === 'Internal failure. Please try again or contact support.') {
          return
        }
        const data = JSON.parse(msg.data)
        onEvent(data)
      },
      onerror (err) {
        errorCounter = errorCounter + 1
        if (errorCounter % 10 === 0) {
          console.log('error', err)
          // snackbar.showSnackbar('Error connecting to server', 'error')
        }
      }
    }).catch(err => {
      console.log('this only exists to ensure the promise is handled', err)
    })
    : console.log('REACT_APP_API_URL is not defined')
}

const ensureTrailingSlash = (url: string): string => {
  if (url.endsWith('/')) {
    return url
  } else {
    return url + '/'
  }
}
