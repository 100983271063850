import { type AuthState } from '@okta/okta-auth-js'
import User from 'types/User'

import { AuthRole } from './const'

export const initUser = (authState: AuthState, userRoles: string[]) => {
  if (authState?.accessToken) {
    const groups = authState?.accessToken?.claims.groups
    const arrayGroups: string[] = Object.values(groups).map(gr => {
      return gr as string
    })

    return new User(arrayGroups, getAuthRole(userRoles))
  }
}

const getAuthRole = (roles: string[]): AuthRole[] => {
  const userRoles = new Set<AuthRole>()
  roles.forEach(role => {
    const authRole = AuthRole[role as keyof typeof AuthRole]
    if (authRole) userRoles.add(AuthRole[role as keyof typeof AuthRole])
  })
  return Array.from(userRoles)
}

export const checkAdminRole = (user: User | undefined) => {
  if (user) return user.hasRole(AuthRole.ADMIN)
}

export const checkDeploymentRole = (user: User | undefined) => {
  if (user) return user.hasRole(AuthRole.DEPLOYMENT_INITIATE)
}
