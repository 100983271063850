import { Button, Label, Modal } from "@nike/eds"
import { type NewDeployment } from "types/Deployment"
import { type NewPublication } from "types/Publication"

interface ConfirmationModalProps {
    rollout: NewPublication | NewDeployment;
    title: string;
    handleCloseModal: () => void;
    submit: () => void;
}

export const ConfirmationModal = ({ handleCloseModal, submit, rollout, title }: ConfirmationModalProps) => {
    const submitNewRollout = () => {
        submit()
        handleCloseModal()
    }

    const publicationProps = {
        'Extension Pack ID:': rollout.extensionPackId,
        'Version:': (rollout as NewPublication).extensionPackLocalVersion,
        'Instance:': rollout.instance.label,
    };

    const deploymentProps = {
        'Extension Pack ID:': rollout.extensionPackId,
        'Global Version:': (rollout as NewDeployment).globalVersion,
        'Instance:': (rollout as NewDeployment).instance.name,
        'Profile:': (rollout as NewDeployment).instance.profile,
    };

    const currentProps = 'globalVersion' in rollout ? deploymentProps : publicationProps
    return (
        <Modal
            isOpen={true}
            onDismiss={handleCloseModal}
            headerSlot={<div className="eds-type--title-5 mr-8 whitespace-nowrap" >{title}</div>}
        >
            <div className="my-4">
                {Object.entries(currentProps).map(([label, key]) => (
                <div className="flex items-baseline" key={key}>
                    <Label font="title-6">{label}</Label>
                    <div className="ml-2">{key}</div>
                </div>
                ))}
            </div>
            <div className="flex justify-end space-x-4">
                <Button onClick={submitNewRollout}>Confirm</Button>
            </div>
        </Modal>
    )
}