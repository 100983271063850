import { type MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import { fromPublicationDTO } from "mapper/PublicationMapper";
import { mainSplitApi } from "redux/store/mainSplitApi";
import { type Publication, type PublicationCreationRequest, type PublicationDTO } from "types/Publication";
import { InitiateSSE } from "utils/InitiateSSE";

const handleCache = (cache: MaybeDrafted<Publication[]>, data: PublicationDTO) => {
    const publication: Publication = fromPublicationDTO(data)
    const index = cache.findIndex(d => d.id === publication.id)
    if (index === -1) {
      cache.unshift(publication)
    } else {
      cache[index] = publication
    }
    return cache
  }

export const publicationApi = mainSplitApi.injectEndpoints({
    endpoints: (builder) => ({
      getPublications: builder.query<Publication[], void>({
          queryFn: () => {
              return { data: [] }
          },
          async onCacheEntryAdded (
            arg,
            { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
          ) {
            const abortCtrl = new AbortController() // Create an abort controller for the SSE connection
            try {
              const handleEvent = (data: PublicationDTO) => {
                updateCachedData(draft => {
                  return handleCache(draft, data)
                })
              }
              InitiateSSE(handleEvent, abortCtrl, 'publications')
              await cacheDataLoaded
            } catch {
              console.log('Unexpected failure')
            }
            await cacheEntryRemoved
            abortCtrl.abort()
          }
        }),
      submitPublication: builder.mutation<Publication, PublicationCreationRequest>({
        query: (body) => ({
            url: '/publications',
            method: 'POST',
            body
        })
      })
    }),
    overrideExisting: false
})

export const { useGetPublicationsQuery, useSubmitPublicationMutation } = publicationApi;