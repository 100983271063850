import { mainSplitApi } from "redux/store/mainSplitApi";
import { type InstanceDTO, type Instance } from "types/Instance";

export const instanceApi = mainSplitApi.injectEndpoints({
    endpoints: builder => ({
        getInstances: builder.query<Instance[], void>({
            query: () => 'stacks'
        }),
        getInstance: builder.query<Instance, string>({
          query: (id) => `stacks/${id}`,
          transformResponse: (response: InstanceDTO) => {
            const instance = response as Instance;
            return instance;
          }
        })
    })
})

export const { useGetInstancesQuery, useGetInstanceQuery } = instanceApi