import { VerticalNavbar, setActiveValueInNavItems } from '@nike/eds'
import { NikeApp } from '@nike/nike-design-system-icons'
import { checkAdminRole } from 'auth/authentication'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { type RootState, useAppSelector } from 'redux/store'

import { type NavItem, deployItem, publishItem, extensionPackViewItem } from './SidebarItems'

const userSelector = (state: RootState) => state.user

export function SideBar() {
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useAppSelector(userSelector)
  const hasRoleChanged = useRef(false)

  const [items, setItems] = useState<NavItem[]>([])

  useEffect(() => {
    if (user) {
      setItems([deployItem, publishItem, extensionPackViewItem])
      if (checkAdminRole(user)) {
        // Add admin items here once
        setItems(prevItems => [...prevItems])
      }
      hasRoleChanged.current = true
    }
  }, [user])

  useEffect(() => {
    if (hasRoleChanged.current) {
      changeIconBasedOnLocation()
      hasRoleChanged.current = false
    }
  }, [items, location])

  // check location on items id if location has id in url
  const changeIconBasedOnLocation = () => {
    for (const item of items) {
      if (item.paths.some(path => location.pathname.includes(path))) {
        setActiveIcon(item.id)
      }
    }
  }

  const handleClick = (item: { id: string }) => {
    navigate('/' + item.id)
  }

  const setActiveIcon = (id: string) => {
    const newItems = setActiveValueInNavItems(id, items)
    if (newItems) {
      setItems(newItems as NavItem[])
    }
  }

  return (
    <div style={{ position: 'fixed', height: '100%' }}>
      <VerticalNavbar
        navLogoSlot={
          <NikeApp width={'56px'} height={'56px'} data-testid={'epoxy-logo'} />
        }
        appName="Epoxy"
        panelLogoSlot={
          <img
            alt="Placeholder Logo Image"
            src="https://unsplash.com/photos/R_0rTS9ENnk/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjQ0MjY4ODM1"
            style={{ objectFit: 'cover' }}
            height="48px"
            width="48px"
          />
        }
        // appSubtitle="Your AWM workflow manager"
        onNavigate={handleClick}
        items={items}
        // bottomItems={bottomItems} // When settings want to be added, can be added at the bottom
      />
    </div>
  )
}
