import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { type Publication } from 'types';

const publicationAdapter = createEntityAdapter<Publication>({
    selectId: publication => publication.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id)
})

export const publicationSlice = createSlice({
    name: 'publication',
    initialState: publicationAdapter.getInitialState(),
    reducers: {
        publicationReceived: publicationAdapter.setOne,
        publicationsReceived: publicationAdapter.setAll
    }
})

export const { publicationReceived, publicationsReceived } = publicationSlice.actions
