import { Button, Select, TextField } from '@nike/eds'
import { Play } from '@nike/nike-design-system-icons'
import { useSubmitDeployMutation } from 'api/deployment'
import { ConfirmationModal } from 'components/confirmation'
import { useEffect, useState } from 'react'
import { showSnackbar } from 'redux/slices/snackbar.slices'
import { dispatch } from 'redux/store'
import { type Instance } from 'types/Instance'

export interface NewDeployFormProps {
    instances: Instance[] | undefined
    isLoading: boolean
    isError: boolean
}

interface NewDeploy {
    extensionPackId: string
    globalVersion: string
    instance: { label: string, value: string, name: string, profile: string }
}

const initialNewDeploy: NewDeploy = {
    extensionPackId: '',
    globalVersion: '',
    instance: { 'label': '', 'value': '', 'name': '', 'profile': '' },
}

export const NewDeployForm = ({ instances, isLoading, isError }: NewDeployFormProps) => {
    const [newDeploy, setNewDeploy] = useState<NewDeploy>(initialNewDeploy)
    const [modalOpen, setModalOpen] = useState(false);
    const [options, setOptions] = useState([{ label: '', value: '', name: '', profile: '' }])
    const [submitDeploy, { isLoading: isSubmitting, isError: submitError }] = useSubmitDeployMutation();
    const [validationErrors, setValidationErrors] = useState({ extensionPackId: '', globalVersion: '', instance: '' })
    const confirmationTitle = 'Are you sure you want to deploy this Extension Pack?'

    useEffect(() => {
        if (isLoading) {
            return
        }
        if (instances) {
            const mappedProfiles = instances.flatMap((instance: Instance) => {
                return Object.keys(instance.profiles).map(profile => {
                    return { label: `${instance.name} - ${profile}`, value: instance.id, name: instance.name, profile }
                });
            });
            setOptions(mappedProfiles)
        }
    }, [instances, isLoading])

    useEffect(() => {
        if (!isSubmitting && !submitError) {
            setNewDeploy(initialNewDeploy)
        }
        if (submitError) {
            dispatch(showSnackbar({ message: 'Failed to submit Deploy', type: 'error' }))
        }
    }, [isSubmitting, submitError])

    const validateDeploy = (newDeploy: NewDeploy): boolean => {
        const errors = {
            extensionPackId: '',
            globalVersion: '',
            instance: ''
        }
        if (newDeploy.extensionPackId.trim() === '') {
            errors.extensionPackId = 'Extension Pack is required'
        }
        if (newDeploy.globalVersion.trim() === '') {
            errors.globalVersion = 'Global Version is required'
        }
        if (newDeploy.instance.value.trim() === '') {
            errors.instance = 'Target Instance is required'
        }
        if (newDeploy.instance.profile.trim() === '') {
            errors.instance = 'Target Profile is required'
        }
        setValidationErrors(errors)
        return Object.values(errors).find(error => error) === undefined
    }

    const createDeployRequest = () => {
        return {
            extensionPackId: newDeploy.extensionPackId,
            globalVersion: newDeploy.globalVersion,
            targetInstanceId: newDeploy.instance.value,
            targetProfile: newDeploy.instance.profile
        }
    }

    const submitDeployHandler = () => {
        const DeployCreationRequest = createDeployRequest();
        if(validateDeploy(newDeploy)) {
            submitDeploy(DeployCreationRequest)
        }
    }

    const handleSelect = (instance: any) => {
        setValidationErrors({
            ...validationErrors,
            instance: '',
        })
        setNewDeploy(prevDeploy => ({
            ...prevDeploy,
            instance
        }))
    }

    const handleChange = (id: string, value: string) => {
        setValidationErrors({
            ...validationErrors,
            [id]: ''
        })
        setNewDeploy(prevDeploy => ({
            ...prevDeploy,
            [id]: value
        }))
    }

    const saveDeploy = () => {
        if (validateDeploy(newDeploy)) {
            setModalOpen(true)
        }
    }

    return (
        <>
            {isLoading || isError ?
                (
                    <span className={'text-center'}>No items to display</span>
                ) :
                (
                    <div className="flex auto h-28">
                        <div className="w-1/5">
                            <TextField
                                id={'extensionPackId'}
                                label={'Extension Pack ID'}
                                placeholder={'Enter Extension Pack ID'}
                                className={'mr-4'}
                                hasErrors={validationErrors.extensionPackId !== ''}
                                errorMessage={validationErrors.extensionPackId}
                                value={newDeploy.extensionPackId}
                                onChange={e => { handleChange(e.target.id, e.target.value) }}
                            />
                        </div>
                        <div className="w-1/5">
                            <TextField
                                id={'globalVersion'}
                                label={'Global Version'}
                                className={'mr-4'}
                                placeholder={'Enter Global Version'}
                                value={newDeploy.globalVersion}
                                hasErrors={validationErrors.globalVersion !== ''}
                                errorMessage={validationErrors.globalVersion}
                                onChange={e => { handleChange(e.target.id, e.target.value) }}
                            />
                        </div>
                        <div className="flex-grow">
                            <Select
                                id={'instance'}
                                label={'Target Instance'}
                                className={'mr-8'}
                                placeholder={'Enter Target Instance'}
                                value={newDeploy.instance}
                                options={options}
                                hasErrors={validationErrors.instance !== ''}
                                errorMessage={validationErrors.instance}
                                onChange={handleSelect}
                            />
                        </div>
                        <div className="place-self-end mb-4">
                            <Button
                                beforeSlot={<Play />}
                                style={{ height: '56px' }}
                                size="medium"
                                variant="primary"
                                onClick={() => { saveDeploy()}}>
                                Deploy
                            </Button>
                        </div>
                        {modalOpen && (
                            <ConfirmationModal handleCloseModal={() => { setModalOpen(false) }} submit={submitDeployHandler} rollout={newDeploy} title={confirmationTitle}/>
                        )}
                    </div>
                )}
        </>
    )
}