
export enum CompletionStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  FAILED = 'FAILED',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
}

export const getPublicationStatus = (publicationStatus: string) => {
  if (publicationStatus === 'epoxy.publication.commandCompleted') {
    return CompletionStatus.COMPLETED
  } else if(publicationStatus === 'epoxy.publication.error') {
    return CompletionStatus.FAILED
  } else {
    return CompletionStatus.NOT_STARTED
  }
}
