import { Icon } from '@nike/eds'
import ReactPaginate from 'react-paginate'

import './paging.css'

interface PagingProps {
  items: any[]
  itemsPerPage: number
  pageCount: number
  setItemOffset: (offset: number) => void
}

export const Paging = ({
  items,
  itemsPerPage,
  pageCount,
  setItemOffset
}: PagingProps) => {
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % items.length
    setItemOffset(newOffset)
  }

  return (
    <div className="flex items-center place-content-center gap-4 paging-class">
      <ReactPaginate
        className="paginate"
        pageClassName="paginate-item"
        pageLinkClassName="paginate-item-link"
        activeClassName="paginate-active"
        activeLinkClassName="paginate-active-link"
        breakLinkClassName="paginate-break-link"
        breakLabel="..."
        previousClassName="paginate-previous"
        previousLabel={<Icon name="CaretLeft" size="s" />}
        nextClassName="paginate-next"
        nextLabel={<Icon name="CaretRight" size="s" />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
      />
    </div>
  )
}
