import { useGetInstanceQuery } from 'api/instance'
import { type DeploymentProgress } from 'types'
import './DeploymentVisualisationHeader.css'

export interface DeploymentVisualisationHeaderProps {
    deployment: DeploymentProgress
}

export const DeploymentVisualisationHeader = ({ deployment }: DeploymentVisualisationHeaderProps) => {
    const totalStages = deployment.stages.length
    const { data:instance } = useGetInstanceQuery(deployment.targetInstanceId)

    return (
        <div className="flex-grow m-4">
            <div className="deployment-header my-2">
                <span className="font-bold">Extension Pack: {deployment.extensionPackId} - Global Version: {deployment.globalVersion}</span>
            </div>
            <div className="deployment-subheader my-2">
                <span className="font-bold">Instance: {instance?.name}</span>
            </div>
            <div className="deployment-subheader my-2">
                <span className="font-bold">Instance Profile: {deployment.targetProfile}</span>
            </div>
            <div className="--eds-type-title-6 mt-4">
                <span className="font-bold">User: {deployment.user}</span>
            </div>
            <div className="deployment-desc mt-4 mb-4 flex justify-between">
                <div className="text-gray-600 mb-2">{`Start: ${deployment.stages[0].timestampFormatted}`}</div>
                <div className="text-gray-600 mb-2">{`End: ${deployment.stages[totalStages - 1].timestampFormatted}`}</div>
                <div className="text-gray-600 mb-2">{`Duration: ${deployment.stages[totalStages - 1].timestamp - deployment.stages[0].timestamp} ms`}</div>
            </div>
        </div>
    )
}