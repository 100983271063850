import { createApi } from '@reduxjs/toolkit/query/react'

import { customBaseQuery } from './CustomBaseQuery'

export const mainSplitApi = createApi({
  reducerPath: 'mainSplitApi',
  tagTypes: ['Deployment','Publication','Instance'],
  baseQuery: customBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL
  }),
  endpoints: (builder) => ({})
})
